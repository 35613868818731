<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>
        Sign Ins
      </v-toolbar-title>
    </v-toolbar>
    <loading-bar v-model="loading"></loading-bar>
    <v-card-text v-if="signIns">
      <v-row dense>

        <v-col cols="12" sm="4">
          <username-and-password :signIns="signIns"></username-and-password>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card height="100%">
            <v-toolbar color="color2 color2Text--text">
              <v-toolbar-title>
                Facebook
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="fbLoading" class="text-center mb-4">
               <v-btn fab :loading="true" top color="color3--text"></v-btn>
            </v-card-text>
            <v-card-text v-if="!fbLoading && !signIns.facebook">
              <h4>To enable Facebook sign in, link your Facebook account by clicking the button below</h4>
            </v-card-text>
            <v-card-text class="text-center" v-if="!fbLoading && !signIns.facebook">
              <div ref="fbBtn"
                class="fb-login-button"
                data-width="300"
                data-max-rows="1"
                data-size="large"
                data-button-type="login_with"
                data-show-faces="false"
                data-auto-logout-link="false"
                data-use-continue-as="true"
                data-scope="public_profile, email"
                onlogin="fbLink()"></div>
            </v-card-text>
            <v-card-text class="text-center pb-0" v-if="!fbLoading && signIns.facebook">
              <h3>Linked Account</h3>
            </v-card-text>
            <v-card-text v-if="!fbLoading && signIns.facebook">
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-row class="fill-height"  align="center">
                      <v-col>
                        <v-avatar>
                          <img :src="signIns.facebook.pictureUrl" alt="">
                        </v-avatar>
                      </v-col>
                      <v-col>
                        <h3>{{signIns.facebook.firstName}} {{signIns.facebook.lastName}}</h3>
                      </v-col>
                      <v-col>
                        <div class="text-right caption grey--text link" @click="confirmUnlink = true"><strong>Unlink</strong></div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-dialog
              v-model="confirmUnlink"
              max-width="450px"
            >
              <v-card>
                <v-toolbar color="warning white--text">
                  <v-toolbar-title>Are you sure?</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  You will no longer be able to sign in to Volleyball Life with Facebebook
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="fbUnlink"
                    :loading="fbLoading"
                  >Continue</v-btn>
                  <v-btn
                    text
                    @click="confirmUnlink = false"
                    color="color3"
                    :disabled="fbLoading"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4" v-if="false">
          <v-card height="100%">
            <v-toolbar color="red darken4" dark>
              <v-toolbar-title>
                Google
              </v-toolbar-title>
            </v-toolbar>
              <v-card-text class="text-center">
                <h2>Coming Soon</h2>
              </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UsernameAndPassword from './UsernameAndPassword.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['altUser'],
  data () {
    return {
      signIns: null,
      confirmUnlink: false,
      fbLoading: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    getSignIns () {
      this.loading = true
      this.$VBL.user.getSignIns()
        .then((response) => {
          this.signIns = response.data
          setTimeout(function () { window.FB.XFBML.parse() }, 300)
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fbLink () {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          this.fbLoading = true
          this.$VBL.user.linkFacebook(response.authResponse)
            .then((response) => {
              this.signIns.facebook = response.data.data
              this.fbLoading = false
            })
            .catch(() => {
              console.log('Fb Link failed')
              this.fbLoading = false
            })
        }
      })
    },
    fbUnlink () {
      this.fbLoading = true
      this.$VBL.user.unlinkFacebook(this.signIns.facebook.id)
        .then((response) => {
          this.signIns.facebook = null
          this.confirmUnlink = false
          setTimeout(function () { window.FB.XFBML.parse() }, 300)
          this.fbLoading = false
        })
        .catch((error) => {
          console.log(error.response)
          this.fbLoading = false
        })
    }
  },
  watch: {
    user: function (n, o) {
      this.getSignIns()
    }
  },
  created () {
    this.getSignIns()
  },
  mounted () {
    window.fbLink = this.fbLink
    // console.log(this.$refs.fbBtn.attributes['data-width'].value)
  },
  components: {
    'username-and-password': UsernameAndPassword
  }
}
</script>

<style scoped>
  .link {
    cursor: pointer;
  }
</style>
