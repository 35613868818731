<template>
  <v-card height="100%">
    <v-toolbar color="color3 color3Text--text">
      <v-toolbar-title>
        <v-icon color="color3Text">fas fa-envelope</v-icon>
        Email
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="text-center pb-0">
      <h3 v-if="passwordMode === 'Change'">Username: {{ signIns.username }}</h3>
      <h3 v-else>You have not enabled email/password sign in</h3>
    </v-card-text>
    <v-card-text>
      <v-card class="link">
        <v-card-text @click="dialog = true">
          <v-container fluid>
            <v-row class="fill-height"  align="center">
              <v-col>
                <v-avatar class="color3">
                  <v-icon color="color3Text">fas fa-key</v-icon>
                </v-avatar>
              </v-col>
              <v-col v-if="passwordMode === 'Change'">
                <h3>Change your password</h3>
              </v-col>
              <v-col v-else>
                <h3>Set a password</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="450px"
      persistent
    >
      <set-password :signIns="signIns" @close="dialog = false" @pw-set="snackbar = true"></set-password>
    </v-dialog>
    <v-snackbar
      bottom
      vertical
      :timeout="5000"
      v-model="snackbar"
    >
      <span v-if="passwordMode === 'Change'">Your password has been changed</span>
      <span v-else>Your password has been set</span>
      <v-btn text color="color3" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import SetPassword from '../Account/SetPassword.vue'

export default {
  props: ['signIns'],
  data () {
    return {
      dialog: false,
      snackbar: false
    }
  },
  computed: {
    passwordMode () {
      return this.signIns && this.signIns.password ? 'Change' : 'Set'
    }
  },
  methods: {

  },
  components: {
    SetPassword
  }
}
</script>

<style scoped>
  .link {
    cursor: pointer;
  }
</style>
